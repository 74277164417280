import startsWith from 'lodash-es/startsWith';
import { getAppNames, registerApplication } from 'single-spa';

import { addPreloader } from 'shared/utils';

export const registerApplications = () => {
  registerApplication({
    name: '@MBO/layout',
    app: (_) => System.import(`@MBO/layout`),
    activeWhen: () => true,
    customProps: {
      data: {
        projectName: 'GIS',
      },
    },
  });

  window.addEventListener('commonData', () => {
    if (!getAppNames().includes('@GIS/journals')) {
      registerApplication({
        name: '@GIS/journals',
        app: (_) =>
          Promise.resolve().then(() => {
            addPreloader();
            return System.import('@GIS/journals');
          }),
        activeWhen: (location) => startsWith(location.pathname, `/journals`),
        customProps: {
          domElementGetter: () => document.getElementById('single-spa-module-container'),
        },
      });
    }

    if (!getAppNames().includes('@GIS/node-management')) {
      registerApplication({
        name: '@GIS/node-management',
        app: (_) =>
          Promise.resolve().then(() => {
            addPreloader();
            return System.import('@GIS/node-management');
          }),
        activeWhen: (location) => startsWith(location.pathname, `/node-management`),
        customProps: {
          domElementGetter: () => document.getElementById('single-spa-module-container'),
        },
      });
    }

    if (!getAppNames().includes('@GIS/bonus')) {
      registerApplication({
        name: '@GIS/bonus',
        app: (_) =>
          Promise.resolve().then(() => {
            addPreloader();
            return System.import('@GIS/bonus');
          }),
        activeWhen: (location) => startsWith(location.pathname, `/bonus`),
        customProps: {
          domElementGetter: () => document.getElementById('single-spa-module-container'),
        },
      });
    }

    if (!getAppNames().includes('@GIS/reports')) {
      registerApplication({
        name: '@GIS/reports',
        app: (_) =>
          Promise.resolve().then(() => {
            addPreloader();
            return System.import('@GIS/reports');
          }),
        activeWhen: (location) => startsWith(location.pathname, `/reports`),
        customProps: {
          domElementGetter: () => document.getElementById('single-spa-module-container'),
        },
      });
    }

    if (!getAppNames().includes('@GIS/betting')) {
      registerApplication({
        name: '@GIS/betting',
        app: (_) =>
          Promise.resolve().then(() => {
            addPreloader();
            return System.import('@GIS/betting');
          }),
        activeWhen: (location) => {
          return startsWith(location.pathname, `/betting`);
        },
        customProps: {
          domElementGetter: () => document.getElementById('single-spa-module-container'),
        },
      });
    }

    if (!getAppNames().includes('@MBO/export-loader')) {
      registerApplication({
        name: '@MBO/export-loader',
        app: (_) => System.import('@MBO/export-loader'),
        activeWhen: () => true,
        customProps: {
          domElementGetter: () => document.getElementById('export-loader-module-container'),
        },
      });
    }
  });
};
